import React, {useState} from 'react';
import type {ReactElement} from 'react';
import {BrowserRouter, Routes, Route, Navigate, Outlet} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import CreateInvitation from '@refinio/one.ui/lib/ui/views/invitation/CreateInvitation.js';

import TrustView from '@/components/trust/TrustView.js';
import AppBarSettings from '@/components/appBar/AppBarSettings.js';
import PageNotFound from '@/components/PageNotFound.js';
import {useAuthenticationState} from '@/hooks/authenticator/hooks.js';
import {useIndexedDBNotificationOrError} from '@/hooks/indexedDB/errorHandling.js';
import EventCalendar from '@/root/calendar/EventCalendar.js';
import ChatRouter from '@/root/chat/ChatRouter.js';
import {getAdditionalAttachmentViews} from '@/root/chat/attachmentViews/attachmentViewRenderers.js';
import ConnectionsView from '@/root/connections/ConnectionsView.js';
import QrScanner from '@/components/qrScanner/QrScanner.js';
import LeuteView from '@/root/contacts/LeuteView.js';
import DebugRouter from '@/root/debug/DebugRouter.js';
import DocumentRouter from '@/root/document/DocumentRouter.js';
import Downloads from '@/root/downloads/Downloads.js';
import Ecpire from '@/root/ecpire/Ecpire.js';
import GroupRouter from '@/root/group/GroupRouter.js';
import IdentityRouter from '@/root/identity/IdentityRouter.js';
import IoMRouter from '@/root/iom/IoMRouter.js';
import JournalRouter from '@/root/journal/JournalRouter.js';
import PatientsRouter from '@/edda/root/patients/PatientsRouter.js';
import ProfileRouter from '@/root/profile/ProfileRouter.js';
import {QuestionnaireRouter} from '@/root/questionnaires/QuestionnaireRouter.js';
import SettingsRouter from '@/root/settings/SettingsRouter.js';
import SomeoneRouter from '@/root/someone/SomeoneRouter.js';
import SignKeysRouter from '@/root/sign_keys/SignKeysRouter.js';
import WBCRouter from '@/root/wbc/WBCRouter.js';
import DiaryRouter from '@/root/diary/DiaryRouter.js';
import BodyTemperatureRouter from '@/root/bodyTemp/BodyTemperatureRouter.js';
import ChatMessageDetailsRouter from '@/root/chat/ChatMessageDetailsRouter.js';
import startGroupChat from '@/root/contacts/StartGroupChat.js';

import {RootRoute} from '@/edda/root/RootRoute.js';
import PageTemplate from '@/edda/components/PageTemplate.js';
import AboutRouter from '@/edda/root/about/AboutRouter.js';
import {DisclaimerBody} from '@/edda/components/onboarding/DisclaimerBody.js';
import type Model from '@/edda/model/Model.js';
import ResearcherDataRequestView from '@/edda/root/researcher/ResearcherDataRequestView.js';
import Home from '@/edda/root/home/Home.js';

import '@/Ui.css';
import '@/Primary.css';
import '@/edda/Ui.css';
import '@/edda/Primary.css';

/**
 * Leute specific Router
 * @param props
 * @constructor
 */
export function AppRouter(props: {model: Model; app: string}): ReactElement {
    useIndexedDBNotificationOrError();
    const i18n = useTranslation();
    const {
        one,
        journalModel,
        leuteModel,
        connections,
        topicModel,
        channelManager,
        iom,
        questionnaireModel,
        notifications,
        documentModel,
        blacklistModel,
        wbcDiffModel,
        diaryModel,
        bodyTemperatureModel,
        researcher
    } = props.model;
    const authenticationState = useAuthenticationState(one);
    const [onboardingProcess, setOnboardingProcess] = useState<boolean>(
        authenticationState === 'logged_in'
    );

    return (
        <BrowserRouter>
            <Routes>
                {!onboardingProcess ? (
                    <Route
                        path="*"
                        element={
                            <PageTemplate className={props.app}>
                                <RootRoute
                                    leuteModel={leuteModel}
                                    one={one}
                                    onDone={() => setOnboardingProcess(true)}
                                    app={props.app}
                                    disclaimerBody={<DisclaimerBody />}
                                />
                            </PageTemplate>
                        }
                    />
                ) : (
                    <>
                        <Route
                            element={
                                <PageTemplate
                                    className={props.app}
                                    isResearcher={researcher.amIResearcher()}
                                >
                                    <Outlet />
                                </PageTemplate>
                            }
                        >
                            <Route index element={<Navigate to="/home" />} />
                            <Route
                                path="home"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.home')}
                                        />
                                        <Home
                                            leuteModel={leuteModel}
                                            documentModel={documentModel}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="ecpire"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('ecpire.title')}
                                        />
                                        <Ecpire
                                            channelManager={channelManager}
                                            questionnaireModel={questionnaireModel}
                                            leuteModel={leuteModel}
                                            topicModel={topicModel}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="calendar"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('calendar.title')}
                                        />
                                        <EventCalendar
                                            journalModel={journalModel}
                                            questionnaireModel={questionnaireModel}
                                            leuteModel={leuteModel}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="settings/*"
                                element={
                                    <>
                                        <AppBarSettings
                                            title={i18n.t('menu.settings')}
                                            mode={APP_BAR_MODE.BROWSE}
                                        />
                                        <SettingsRouter
                                            leuteModel={leuteModel}
                                            one={one}
                                            connections={connections}
                                            channelManager={channelManager}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="chat/*"
                                element={
                                    <ChatRouter
                                        topicModel={topicModel}
                                        leuteModel={leuteModel}
                                        channelManager={channelManager}
                                        notifications={notifications}
                                        attachmentViewAdditions={getAdditionalAttachmentViews(
                                            questionnaireModel,
                                            bodyTemperatureModel,
                                            wbcDiffModel,
                                            diaryModel,
                                            {
                                                researcher: {
                                                    getResearcherId:
                                                        researcher.getResearcherId.bind(researcher),
                                                    isResearcher:
                                                        researcher.amIResearcher.bind(researcher),
                                                    shareData:
                                                        researcher.shareDataWithResearcher.bind(
                                                            researcher
                                                        )
                                                }
                                            }
                                        )}
                                    />
                                }
                            />
                            {researcher.amIResearcher() && (
                                <Route
                                    path="researcher-data-request"
                                    element={
                                        <ResearcherDataRequestView
                                            topicModel={topicModel}
                                            leuteModel={leuteModel}
                                        />
                                    }
                                />
                            )}
                            <Route
                                path="message/*"
                                element={
                                    <ChatMessageDetailsRouter
                                        leuteModel={leuteModel}
                                        channelManager={channelManager}
                                    />
                                }
                            />
                            <Route
                                path="profile/*"
                                element={
                                    <ProfileRouter
                                        leuteModel={leuteModel}
                                        topicModel={topicModel}
                                        getTrustView={hashes => (
                                            <TrustView leuteModel={leuteModel} hashes={hashes} />
                                        )}
                                    />
                                }
                            />
                            <Route
                                path="someone/*"
                                element={
                                    <SomeoneRouter
                                        leuteModel={leuteModel}
                                        topicModel={topicModel}
                                        getTrustView={hashes => (
                                            <TrustView leuteModel={leuteModel} hashes={hashes} />
                                        )}
                                    />
                                }
                            />
                            <Route
                                path="group/*"
                                element={
                                    <GroupRouter
                                        leuteModel={leuteModel}
                                        topicModel={topicModel}
                                        blacklistModel={blacklistModel}
                                    />
                                }
                            />
                            <Route
                                path="iom/*"
                                element={
                                    <IoMRouter
                                        leuteModel={leuteModel}
                                        iomRequestManager={iom.requestManager}
                                    />
                                }
                            />
                            <Route
                                path="debug/*"
                                element={
                                    <DebugRouter
                                        leuteModel={leuteModel}
                                        connectionsModel={connections}
                                        channelManager={channelManager}
                                        iomManager={iom}
                                    />
                                }
                            />
                            <Route
                                path="contacts"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.VIEW}
                                            title={i18n.t('menu.contacts')}
                                        />
                                        <LeuteView
                                            topicModel={topicModel}
                                            leuteModel={leuteModel}
                                            notifications={notifications}
                                            blacklistModel={blacklistModel}
                                            getTrustView={hashes => (
                                                <TrustView
                                                    leuteModel={leuteModel}
                                                    hashes={hashes}
                                                />
                                            )}
                                            startGroupChat={startGroupChat}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="connections"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.connections')}
                                        />
                                        <ConnectionsView
                                            connectionsModel={connections}
                                            leuteModel={leuteModel}
                                            iomManager={iom}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="invitePartner"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.CHEVRON}
                                            title={i18n.t('invitations.person')}
                                            navigateBackOnClose={'/connections'}
                                        />
                                        <CreateInvitation
                                            connectionsModel={connections}
                                            iomRequestManager={iom.requestManager}
                                            inviteAcceptedRoute="/connections"
                                            mode="iop"
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="inviteDevice"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.CHEVRON}
                                            title={i18n.t('invitations.device')}
                                            navigateBackOnClose={'/connections'}
                                        />
                                        <CreateInvitation
                                            connectionsModel={connections}
                                            iomRequestManager={iom.requestManager}
                                            inviteAcceptedRoute="/connections"
                                            mode="iom"
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="invites/*"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.connections')}
                                        />
                                        <ConnectionsView
                                            connectionsModel={connections}
                                            leuteModel={leuteModel}
                                            iomManager={iom}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="qr-scanner"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.CHEVRON}
                                            title={i18n.t('invitations.qrScanner.title')}
                                            navigateBackOnClose={'/connections'}
                                        />
                                        <QrScanner
                                            connectionsModel={connections}
                                            iomManager={iom}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="identity/*"
                                element={
                                    <IdentityRouter
                                        connectionsModel={connections}
                                        leuteModel={leuteModel}
                                        topicModel={topicModel}
                                        getTrustView={hashes => (
                                            <TrustView leuteModel={leuteModel} hashes={hashes} />
                                        )}
                                    />
                                }
                            />
                            <Route
                                path="journal/*"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.journal')}
                                        />
                                        <JournalRouter
                                            channelManager={channelManager}
                                            journalModel={journalModel}
                                            questionnaireModel={questionnaireModel}
                                            leuteModel={leuteModel}
                                            topicModel={topicModel}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="questionnaire/*"
                                element={
                                    <QuestionnaireRouter questionnaireModel={questionnaireModel} />
                                }
                            />
                            <Route
                                path="patients/*"
                                element={
                                    <PatientsRouter
                                        questionnaireModel={questionnaireModel}
                                        leuteModel={leuteModel}
                                        channelManager={channelManager}
                                        topicModel={topicModel}
                                        notifications={notifications}
                                        journalModel={journalModel}
                                        documentModel={documentModel}
                                        getTrustView={hashes => (
                                            <TrustView leuteModel={leuteModel} hashes={hashes} />
                                        )}
                                    />
                                }
                            />
                            <Route
                                path="document/*"
                                element={<DocumentRouter documentModel={documentModel} />}
                            />
                            <Route
                                path="diary/*"
                                element={
                                    <DiaryRouter
                                        diaryModel={diaryModel}
                                        channelManager={channelManager}
                                    />
                                }
                            />
                            <Route
                                path="bodyTemperature/*"
                                element={
                                    <BodyTemperatureRouter
                                        bodyTemperatureModel={bodyTemperatureModel}
                                        channelManager={channelManager}
                                    />
                                }
                            />
                            <Route path="signkeys/*" element={<SignKeysRouter />} />
                            <Route
                                path="wbc/*"
                                element={
                                    <WBCRouter
                                        wbcDiffModel={wbcDiffModel}
                                        channelManager={channelManager}
                                    />
                                }
                            />
                            <Route
                                path="Downloads"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.downloads')}
                                        />
                                        <Downloads />
                                    </>
                                }
                            />
                            <Route path="about/*" element={<AboutRouter />} />
                            <Route path="*" element={<PageNotFound />} />
                        </Route>
                    </>
                )}
            </Routes>
        </BrowserRouter>
    );
}
