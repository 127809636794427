import {useCallback, type ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import DocumentModel from '@refinio/one.models/lib/models/DocumentModel.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';

import type {QuestionnaireMenu} from '@/components/widgetMenu/items/QuestionnaireItem.js';
import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import WidgetList from '@/components/widgetMenu/WidgetList.js';
import DocumentItem from '@/components/widgetMenu/items/DocumentItem.js';
import ImageItem from '@/components/widgetMenu/items/ImageItem.js';
import BodyTemperatureItem from '@/components/widgetMenu/items/BodyTemperatureItem.js';
import WBCItem from '@/components/widgetMenu/items/WBCItem.js';
import DiaryItem from '@/components/widgetMenu/items/DiaryItem.js';
import QuestionnaireItem from '@/components/widgetMenu/items/QuestionnaireItem.js';

const questionnaireMenu: QuestionnaireMenu[] = [
    {
        entry: MENU_ENTRY.GeneralFeedbackQuestionnaire,
        name: 'general_feedback_edda'
    },
    {entry: MENU_ENTRY.LFTStudyQuestionnaire, name: 'LFT Study Questionnaire'},
    {
        entry: MENU_ENTRY.CervicalCancerScreeningAndTreatmentRegisterQuestionnaire,
        name: 'cervical_cancer_screeninc_and_treatment_register'
    },
    {
        entry: MENU_ENTRY.IFCPC_colposcopic_terminology_cervix,
        name: '2011_IFCPC_colposcopic_terminology_cervix'
    },
    {
        entry: MENU_ENTRY.ColposcopyExaminationRecord,
        name: 'colposcopy_examination_record'
    },
    {
        entry: MENU_ENTRY.SpecificFeedbackQuestionnaire,
        name: 'specific_feedback_edda'
    }
];

export default function PatientWidget(props: {
    documentModel: DocumentModel;
    ownerId: SHA256IdHash<Person>;
}): ReactElement {
    const i18n = useTranslation();

    const onDocumentSubmit = useCallback(
        async (file: File) =>
            props.documentModel.addDocument(
                await file.arrayBuffer(),
                file.type,
                file.name,
                DocumentModel.channelId,
                props.ownerId
            ),
        [props.ownerId, props.documentModel]
    );

    return (
        <WidgetList title={i18n.t('home.widgetTitle')}>
            <QuestionnaireItem menu={questionnaireMenu} dataOwner={props.ownerId} />
            <DocumentItem onDocumentSubmit={onDocumentSubmit} />
            <ImageItem onDocumentSubmit={onDocumentSubmit} />
            <BodyTemperatureItem dataOwner={props.ownerId} />
            <WBCItem dataOwner={props.ownerId} />
            <DiaryItem dataOwner={props.ownerId} />
        </WidgetList>
    );
}
