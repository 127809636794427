import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const LFTStudyQuestionnaire: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://refinio.one/questionaire/LFTStudy',
    name: 'LFT Study Questionnaire',
    title: 'LFT Study Questionnaire',
    status: 'active',
    item: [
        {
            linkId: 'enrollment_group',
            prefix: '1',
            type: 'group',
            text: 'Enrollment Group',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'enrollment',
                    prefix: '1.1',
                    type: 'date',
                    text: 'Enrollment Date',
                    disabledDisplay: 'protected',
                    extension: [
                        {
                            url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                            valueDate: new Date().toISOString()
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'birth_year',
            prefix: '2',
            type: 'integer',
            text: 'Birth Year',
            disabledDisplay: 'protected',
            extension: [
                {
                    url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                    valueInteger: 1900
                },
                {
                    url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                    valueInteger: new Date().getFullYear()
                }
            ]
        },
        {
            linkId: 'Parity',
            prefix: '3',
            type: 'group',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'number_of_pregnancies',
                    prefix: '3.1',
                    type: 'integer',
                    text: 'Number of Pregnancies',
                    disabledDisplay: 'protected'
                },
                {
                    linkId: 'number_of_deliveries',
                    prefix: '3.2',
                    type: 'integer',
                    text: 'Number of Deliveries',
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            linkId: 'contraceptive_method',
            prefix: '4',
            type: 'open-choice',
            text: 'Contraceptive method',
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'pill',
                        display: 'Pill'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'iud',
                        display: 'IUD'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'condom',
                        display: 'Condom'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'others',
                        display: 'Others'
                    }
                }
            ]
        },
        {
            linkId: 'medical_condition',
            prefix: '5',
            type: 'group',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'infection',
                    prefix: '5.1',
                    type: 'open-choice',
                    text: 'Infection',
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'malaria',
                                display: 'Malaria'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'tuberculosis',
                                display: 'Tuberculosis'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'hiv',
                                display: 'HIV'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'others',
                                display: 'Others'
                            }
                        }
                    ]
                },
                {
                    linkId: 'cancer',
                    prefix: '5.2',
                    type: 'open-choice',
                    text: 'Cancer',
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'uterus',
                                display: 'Uterus'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'breast',
                                display: 'Breast'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'gi-tract',
                                display: 'GI-Tract'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'blood',
                                display: 'Blood'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'others',
                                display: 'Others'
                            }
                        }
                    ]
                },
                {
                    linkId: 'organ_disease',
                    prefix: '5.3',
                    type: 'open-choice',
                    text: 'Organ disease',
                    disabledDisplay: 'protected',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'heart',
                                display: 'Heart'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'lung',
                                display: 'Lung'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'gi_tract',
                                display: 'GI tract'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'kidney',
                                display: 'Kidney'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://refinio.one/questionaire/LFTStudy',
                                code: 'diabetes',
                                display: 'Diabetes'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'hiv_status',
            prefix: '6',
            type: 'choice',
            text: 'HIV Status',
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'positive',
                        display: 'Positive'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'negative',
                        display: 'Negative'
                    }
                }
            ]
        },
        {
            linkId: 'lft_hpv_test',
            prefix: '7',
            type: 'choice',
            text: 'LFT HPV Test',
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'positive',
                        display: 'Positive (> 1000ng)'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'weak_positive',
                        display: 'Weak Positive (700 -1000 ng)'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'negative',
                        display: 'Negative (< 700)'
                    }
                }
            ]
        },
        {
            linkId: 'via_result',
            prefix: '8',
            type: 'choice',
            text: 'VIA Result',
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'suspicious',
                        display: 'Suspicious'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'nonsuspicious',
                        display: 'Non-Suspicious'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'unclear',
                        display: 'Unclear'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'not_done',
                        display: 'Not Done'
                    }
                }
            ]
        },
        {
            linkId: 'colposcopy_findings',
            prefix: '9',
            type: 'choice',
            text: 'Colposcopy findings',
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'suspicious',
                        display: 'Suspicious (see colposcopic questionnaire)'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'nonsuspicious',
                        display: 'Nonsuspicious'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'unclear',
                        display: 'Unclear'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'not_done',
                        display: 'Not Done'
                    }
                }
            ]
        },
        {
            linkId: 'biopsy_Histology',
            prefix: '10',
            type: 'string',
            text: 'Biopsy Histology',
            disabledDisplay: 'protected'
        },
        {
            linkId: 'treatment',
            prefix: '11',
            type: 'choice',
            text: 'Treatment',
            disabledDisplay: 'protected',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'leep',
                        display: 'Leep'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'thermoablation',
                        display: 'Thermoablation'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'cryosation',
                        display: 'Cryosation'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'conization',
                        display: 'Conization'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://refinio.one/questionaire/LFTStudy',
                        code: 'others',
                        display: 'Others'
                    }
                }
            ]
        },
        {
            linkId: 'referral',
            prefix: '12',
            type: 'group',
            text: 'Referral',
            disabledDisplay: 'protected',
            item: [
                {
                    linkId: 'address',
                    prefix: '12.1',
                    type: 'string',
                    text: 'Address',
                    disabledDisplay: 'protected'
                },
                {
                    linkId: 'name',
                    prefix: '12.2',
                    type: 'string',
                    text: 'name of physician/midwife/health worker',
                    disabledDisplay: 'protected'
                }
            ]
        },
        {
            linkId: 'referral_remarks',
            prefix: '13',
            type: 'string',
            text: 'Referral remarks',
            disabledDisplay: 'protected'
        }
    ]
};
